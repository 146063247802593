<template>
  <div class="content">
    <header-nav></header-nav>
    <div class="body-con">
      <div class="top-tit">
        <div class="back-blue">
          <img :src="require('@/assets/img/cl.png')" alt="" />
        </div>
        <div class="proinfo">
          <div class="join-us">开发者园地</div>
        </div>
      </div>
      <div class="w-con">
        <div class="bigtit">前言</div>
        <div class="word-content">
          我们提供了一套针对C++及python语言开发算法的接口，可以根据我们的标准接口规范创建自己的算法动态链接库，或者python包，进行回测训练及交易，如下是详细使用规范：
        </div>
        <div class="bigtit">外接语言部分</div>
        <div class="word-content">
          本工具将支持外接
          C++开发的WINDOWS动态链接库DLL，以及PYTHON语言的调用。本次先介绍DLL插件的外挂。
        </div>
        <div class="bigtit">1.函数命名约定</div>
        <div class="word-content">
          指标宏语言(script)编写，为了区分内置静态函数和外部动态加载函数，对函数命名做了一些约定：
        </div>
        <div class="word-content">
          ■ 内置函数直接以函数名称开头，比如SIN, MAX,等。
        </div>
        <div class="word-content">
          ■
          外挂DLL函数，以关键词“DLL_”和文件名（文件名称限定字幕数字，其它字符不支持）开头，后跟”@”符号与函数，不区分大小写，
          比如”dll_file@fourier”，表示dll插件file文件中的函数 fourier。
        </div>
        <div class="word-content">
          ■
          外挂PY语言（需安装完整的PYTHON环境），以文件名（文件名称限定字幕数字，其它字符不支持）和关键词“PY_”开头，区分大小写，
          比如”py_func@pyfile”，表示启动运行python的pyfile.py这个文件，并运行func这个函数。
        </div>
        <div class="bigtit">2. DLL插件的注册</div>
        <div class="word-content">
          在策略编辑的窗口，使用“注册DLL”按钮，如下图所示：
        </div>
        <div class="images">
          <img :src="require('@/assets/img/01.png')" alt="" />
        </div>
        <div class="word-content">点击“注册DLL”后，进入如下界面。</div>
        <div class="images">
          <img :src="require('@/assets/img/02.png')" alt="" />
        </div>
        <div class="word-content">
          如上所示，先选择导入一个已经编译测试好好了的DLL文件，导入后如果该文件是一个新的则需要注册全部函数，如果已经注册过，则会自动把原来注册的函数全部列出来。然后对该DLL的函数进行增删维护（如果要修改，可以先删除再增加）。如下图所示：
        </div>
        <div class="images">
          <img :src="require('@/assets/img/03.png')" alt="" />
        </div>
        <div class="word-content">
          用户添加的函数名称，系统会自动增加前缀, 格式为“DLL_” + 文件名 +
          “@”函数名，
          比如刚刚添加了一个名称为Linear_regression的函数，则系统生成的函数全名为：
        </div>
        <div class="word-content">
          DLL_ALGO_PLUGIN.DLL@LINEAR_REGRESSION，以后在脚本语言中如果需要使用该函数，则需要书写该全名，这样编译器在编译的时候会自动搜索导向到指定库文件名称的指定函数。
        </div>
        <div class="word-content">
          注意，如果导入的文件是一个非法的DLL（接口函数不存在），则会出现如下提示报错，不能通过。
        </div>
        <div class="images image-lit">
          <img :src="require('@/assets/img/04.png')" alt="" />
        </div>
        <div class="word-content">
          添加函数完成以后，点击“保存”按钮，即可实现了注册。
        </div>
        <div class="word-content">
          这时候再点击插入函数页面，则会在“外挂插件函数”中看见刚刚添加的这些函数。
        </div>
        <div class="images">
          <img :src="require('@/assets/img/05.png')" alt="" />
        </div>
        <div class="word-content">
          插件注册完成后，需要重新启动软件，才能生效，这时候打开指标编辑器，点击插入刚刚增加的函数，则会出现颜色变蓝，以及使用说明的浮动信息提示，如下图所示：
        </div>
        <div class="images">
          <img :src="require('@/assets/img/06.png')" alt="" />
        </div>
        <div class="bigtit">3. 如何在脚本语言(SCRIPT)中调用算法盒子函数</div>
        <div class="word-content">这里特别对几个重要的名词进行释义：</div>
        <div class="word-content">
          <span style="font-weight: bold">参数：</span>
          脚本语言调用函数是传递的的每一个参数，默认是一个一维数组，比如LOG(OPEN)中的OPEN是由一系列K线开盘价的数组组成。
        </div>
        <div class="word-content">
          <span style="font-weight: bold">元素：</span>
          由于每一个参数都是一个一维数组，其中的每一个值被称为元素。
          一个参数由其包含的多个元素组成。
        </div>
        <div class="word-content">
          <span style="font-weight: bold">返回值：</span>
          返回值默认也是一个一维数组值，其元素顺序与输入参数的元素顺序一一对应,返回值也可以成为一个返回结果元素集。
        </div>
        <div class="word-content" style="margin-bottom: 30px">
          <span style="font-weight: bold">多参数：</span>
          一个函数的调用，可能会传递两个以上的参数，即多参数。由于每个参数有其包含的多个元素，所以多参数的数据微观存储结构将是一个多维数组，也可以成为矩阵。
        </div>
        <div class="word-content">■ 单参数的函数脚本语言调用规范如下：</div>
        <div class="code-part">
          <prism-editor
            class="my-editor"
            v-model="code1"
            :highlight="highlighter"
            :line-numbers="lineNumbers"
          >
          </prism-editor>
        </div>
        <div class="word-content" style="margin-bottom: 30px">
          如上，根据CLOSE参数，直接使用DLL_FILE@FUNC01函数计算结果，并按照与CLOSE同样的时序返回结果。
        </div>
        <div class="word-content">
          ■ 多参数，输入参数的个数不做限制，返回一个结果。
        </div>
        <div class="code-part">
          <prism-editor
            class="my-editor"
            v-model="code2"
            :highlight="highlighter"
            :line-numbers="lineNumbers"
          >
          </prism-editor>
        </div>
        <div class="word-content">有关返回矩阵的函数调用方法，后续再增加。</div>
        <div class="bigtit">4，如何使用VISUAL C++编写DLL的规范</div>
        <div class="word-content">
          有关DLL的编写常规规范，本文不做说明。这里只介绍一下如何定义外挂函数的接口规范，以及初始化、参数传递等方法，并且给除了基于visual
          c++的工程模板和一些函数编写例子。
        </div>
        <div class="word-content" style="margin-bottom: 30px">
          DLL程序设计的时候，必须提供如下5个几口函数：
        </div>
        <div class="word-content">
          ■ 初始化函数，此函数负责算法函数的名称和地址映射。
        </div>
        <div class="code-part">
          <prism-editor
            class="my-editor"
            v-model="code3"
            :highlight="highlighter"
            :line-numbers="lineNumbers"
          >
          </prism-editor>
        </div>
        <div class="word-content">
          ■
          单变量函数，输入和输出是一个变量而非数组或者矩阵。该函数暂时保留未用。
        </div>
        <div class="code-part">
          <prism-editor
            class="my-editor"
            v-model="code4"
            :highlight="highlighter"
            :line-numbers="lineNumbers"
          >
          </prism-editor>
        </div>
        <div class="word-content">
          ■ 单参数函数，输入和输出都是一个一维数组，该函数可能是使用最多的一种。
        </div>
        <div class="code-part">
          <prism-editor
            class="my-editor"
            v-model="code5"
            :highlight="highlighter"
            :line-numbers="lineNumbers"
          >
          </prism-editor>
        </div>
        <div class="word-content">
          ■ 多参数函数，输入是多个参数，输出是一个一维数组。
        </div>
        <div class="code-part">
          <prism-editor
            class="my-editor"
            v-model="code6"
            :highlight="highlighter"
            :line-numbers="lineNumbers"
          >
          </prism-editor>
        </div>
        <div class="word-content">
          ■
          矩阵函数，输入是多个参数，输出亦是多个参数。也即输入和输出均为矩阵。该函数暂时保留未用。
        </div>
        <div class="code-part">
          <prism-editor
            class="my-editor"
            v-model="code7"
            :highlight="highlighter"
            :line-numbers="lineNumbers"
          >
          </prism-editor>
        </div>
        <div class="word-content">
          脚本语言对外部函数的调用，最终底层实现了一个C++的DLL接口函数的调用，这里做一个对应关系说明：
        </div>
        <div class="word-content">
          <P>■ SCRIP和DLL之间在 单参数模式调用下的对应关系如下：</P>
          <P
            >SCRIPT语句：<span style="color: #fb220b"
              >A:=DLL_FILE1@FUNC01(CLOSE);</span
            >
          </P>
          <P>DLL接口函数如下：</P>
        </div>
        <div class="code-part">
          <prism-editor
            class="my-editor"
            v-model="code8"
            :highlight="highlighter"
            :line-numbers="lineNumbers"
          >
          </prism-editor>
        </div>
        <div class="word-content">
          <P
            >参数分别为 函数名称，
            以INPUT为输入参数，返回结果在OUTPUT，最后通过脚本解释器底层把OUTPUT的值赋给变量A
            。</P
          >
          <P>dynamic_func_b的c++函数原型如下： </P>
        </div>
        <div class="code-part">
          <prism-editor
            class="my-editor"
            v-model="code9"
            :highlight="highlighter"
            :line-numbers="lineNumbers"
          >
          </prism-editor>
        </div>
        <div class="word-content">
          <P
            >参数分别为 函数名称， std::deque &lt; input
            &gt;是一个二维数组参数，返回结果在OUTPUT，最后通过脚本解释器底层把OUTPUT的值赋给变量A
            .</P
          >
          <P>dynamic_func_c的c++函数原型如下： </P>
        </div>
        <div class="code-part">
          <prism-editor
            class="my-editor"
            v-model="code10"
            :highlight="highlighter"
            :line-numbers="lineNumbers"
          >
          </prism-editor>
        </div>
        <div class="word-content">
          另外，函数增加两个一个初始化函数 bool dynamic_init();
          其主要作用是创建一个函数映射表，即每个函数的名称和其多个重载函数的地址映射。C++函数原型和指针极其映射表的定义结构如下：
        </div>
        <div class="code-part">
          <prism-editor
            class="my-editor"
            v-model="code11"
            :highlight="highlighter"
            :line-numbers="lineNumbers"
          >
          </prism-editor>
        </div>
        <div class="word-content">函数初始化的编写实例如下：</div>
        <div class="code-part">
          <prism-editor
            class="my-editor"
            v-model="code12"
            :highlight="highlighter"
            :line-numbers="lineNumbers"
          >
          </prism-editor>
        </div>
        <div class="word-content" style="margin-bottom: 30px">
          最后，用visual c++编写的DLL工程示例规范，详见algo_plugin。
        </div>
        <div class="bigtit">外接语言部分</div>
        <div class="word-content">
          Python作为近年来流行热门和使用量增长最快的语言，特别是在人工智能和量化交易这两个领域内被广泛使用。
        </div>
        <div class="word-content">
          蓝目训练器”支持python语言的编辑与运行环境，并且支持调用python的标准化函数的调用。
        </div>
        <div class="word-content">python编辑器的启动在如下图的第四个按钮。</div>
        <div class="images">
          <img :src="require('@/assets/img/07.png')" alt="" />
        </div>
        <div class="word-content">点击后出现python窗口。</div>
        <div class="images">
          <img :src="require('@/assets/img/08.png')" alt="" />
        </div>
        <div class="word-content">
          可以通过“新建”，创建新的空白窗口输入或者从其他地方粘贴PYTHON代码进来。也可以打开一个python文件（默认后缀名.py），打开后自动运行，也可以点击“运行”按钮进行运行。
        </div>
        <div class="images">
          <img :src="require('@/assets/img/09.png')" alt="" />
        </div>
        <div class="word-content">
          这里主要介绍如何编写python函数并且导出供行情分析指标显示以及产生交易信号（买卖）。
        </div>
        <div class="word-content">
          <span>首先，</span>
          作为分析软件的script语言调用的函数，都定义了标准的输入和输出接口，这里的python函数的输入输出，和dll插件使用统一的接口规范，调用的时候的唯一区别是通过函数命名规范来区分是调用了一个dll插件还是python函数，前面已经提及，这里在重申如下：
        </div>
        <div class="word-content">
          ■外挂DLL函数，以关键词“DLL_”和文件名（文件名称限定字幕数字，其它字符不支持）开头，后跟”@”符号与函数，不区分大小写，
          比如”dll_file@fourier”，表示dll插件file文件中的函数 fourier。
        </div>
        <div class="word-content">
          ■外挂PY语言（需安装完整的PYTHON环境），以文件名（文件名称限定字幕数字，其它字符不支持）和关键词“PY_”开头，区分大小写，
          比如”py_func@pyfile”，表示启动运行python的pyfile.py这个文件，并运行func这个函数。
        </div>
        <div class="word-content" style="margin-bottom: 30px">
          <span>其次，</span>
          python函数的定义规范，必须按照单参数函数、多参数函数和矩阵函数的输入输出定义方法来编写python函数，由于目前系统仅需要一个数组的输入和输出函数，以及多数组输入单数组数组这两种标准（其它标准后续陆续介绍），因此把两种形式的python函数编写示例介绍如下：
        </div>
        <div class="word-content">
          a，一个数组输入、一个数组输出的python示例：
        </div>
        <div class="code-part">
          <prism-editor
            class="my-editor"
            v-model="code13"
            :highlight="highlighter"
            :line-numbers="lineNumbers"
          >
          </prism-editor>
        </div>
        <div class="word-content">
          b,多个数组输入、一个数组输出的python示例：
        </div>
        <div class="code-part">
          <prism-editor
            class="my-editor"
            v-model="code14"
            :highlight="highlighter"
            :line-numbers="lineNumbers"
          >
          </prism-editor>
        </div>
        <div class="word-content">再次，script调用python可使用如下方式：</div>
        <div class="code-part">
          <prism-editor
            class="my-editor"
            v-model="code15"
            :highlight="highlighter"
            :line-numbers="lineNumbers"
          >
          </prism-editor>
        </div>
        <div class="word-content">
          <span style="font-weight: bold">最后</span>
          ，本系统提供了一个python函数的指标显示，可以使用pytest调出来显示，如下图所示：
        </div>
        <div class="images">
          <img :src="require('@/assets/img/10.png')" alt="" />
        </div>
        <div class="word-content">
          另外，系统提供了一个python的导出函数编写例子文件，详见rom.py(在应用程序文件夹python目录下)，可以使用python编辑器打开显示，如下图所示：
        </div>
        <div class="images">
          <img :src="require('@/assets/img/11.png')" alt="" />
        </div>

        <div class="guthub" @click="toPlug">
          <div class="guthubimg">
            <img :src="require('@/assets/img/guthub.png')" alt="" />
          </div>
          <div class="guthubtit">GITHUB</div>
        </div>
      </div>
    </div>

    <floor></floor>
  </div>
</template>

<script>
import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css"; // import the styles somewhere

// import highlighting library (you can use any library you want just return html string)
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism-tomorrow.css"; // import syntax highlighting styles

import HeaderNav from "@/components/Header.vue";

import Floor from "@/components/Floor.vue";

export default {
  components: {
    HeaderNav,
    Floor,
    PrismEditor,
  },
  data: () => ({
    code1: "A:=DLL_FILE1@FUNC01(CLOSE);",
    code2: "B:=DLL_FILE1@FUNC03(OPEN,CLOSE,…);",
    code3: "typedef bool (*i_init)();",
    code4:
      "typedef void (*i_dynamic_func_a)(float _arg_input, float &_arg_output);",
    code5:
      "typedef void (*i_dynamic_func_b)(std::deque <float> _arg_input, std::deque<float> &_arg_output);",
    code6:
      "typedef void (*i_dynamic_func_c)(std::deque< std::deque<float> > _arg_input, std::deque<float> &_arg_output);",
    code7: `typedef void (*i_dynamic_func_d)(std::deque< std::deque<float> > _arg_input, 
std::deque< std::deque<float> > &_arg_output);`,
    code8: `dynamic_func_b("DLL_FILE1@FUNC01", INPUT,OUTPUT);`,
    code9: ` typedef void (*i_dynamic_func_b)(std::deque<float> _arg_input,
    std::deque<float> &_arg_output);`,
    code10: `typedef void (*i_dynamic_func_c)(std::deque< std::deque<float> > 
        _arg_input, std::deque<float> &_arg_output);`,

    code11: ` typedef bool (*i_init)();
    typedef void (*i_dynamic_func_a)(float _arg_input, float &_arg_output);
    typedef void (*i_dynamic_func_b)(std::deque<float> _arg_input, std::deque<float> &_arg_output);
    typedef void (*i_dynamic_func_c)(std::deque< std::deque<float> > _arg_input, std::deque<float> &_arg_output);
    typedef void (*i_dynamic_func_d)(std::deque< std::deque<float> > _arg_input, std::deque< std::deque<float> >
    &_arg_output);
    typedef struct
        {
            i_dynamic_func_a dynamic_func_a_;
            i_dynamic_func_b dynamic_func_b_;
            i_dynamic_func_c dynamic_func_c_;
        } 
      tag_FUNC_LIST;`,
    code12: `bool dynamic_init()
      {
      tag_FUNC_LIST _func_list;
      _func_list.dynamic_func_a_ = (i_dynamic_func_a)CAlgorithms::Example01;
      _func_list.dynamic_func_b_ = (i_dynamic_func_b)CAlgorithms::Example02;
      _func_list.dynamic_func_c_ = (i_dynamic_func_c)CAlgorithms::Example03;
      g_map_functions.insert(std::make_pair("EXAMPLE0",_func_list));
      _func_list.dynamic_func_a_ = (i_dynamic_func_a)CAlgorithms::Example11;
      _func_list.dynamic_func_b_ = (i_dynamic_func_b)CAlgorithms::Example12;
      _func_list.dynamic_func_c_ = (i_dynamic_func_c)CAlgorithms::Example13;
      g_map_functions.insert(std::make_pair("EXAMPLE1",_func_list));
      // To do, add more functions in the following ......
      //...
      return true;
      }`,
    code13: `def testList(input):
      t = [float(x) for x in input]
      df = ta.MA(np.array(t),timeperiod=5)
      return df.tolist()`,
    code14: `def testList2(input1,input2):
      a = np.array(input1)
      b = np.array(input2)
      c = a+b
      output = []
      for x in c:
      output.append(x)
      return output`,
    code15: ` A:PY_ROM@TESTLIST2(high,low);`,

    lineNumbers: true, // true为编辑模式， false只展示不可编辑
  }),
  methods: {
    highlighter(code) {
      return highlight(code, languages.js); //returns html
    },
    toPlug() {
      window.location.href = "https://github.com/blueye-com/algo-plug";
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  width: 100%;
}
/* required class */
.my-editor {
  background: #2d2d2d;
  color: #ccc;
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
}

/* optional */
.prism-editor__textarea:focus {
  outline: none;
}

/* not required: */

.body-con {
  width: 100%;
}
.top-tit {
  width: 100%;
  padding: 40px 0;
  box-sizing: border-box;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(45deg, #020031, #6d3353);
}
.join-us {
  width: 100%;
  font-size: 30px;
  line-height: 30px;
  display: flex;
  color: #ffffff;
  box-sizing: border-box;
  align-items: center;
  font-weight: bold;
  padding-right: 10px;
  justify-content: center;
}
.back-blue {
  display: flex;
  align-items: center;
  justify-content: center;
}
.w-con {
  max-width: 1040px;
  margin: 0 auto;
  padding: 20px;
}
.bigtit {
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  padding: 15px 0 5px;
}
.word-content {
  text-align: left;
  text-indent: 2em;
  padding: 20px 0;
  line-height: 26px;
}
.images {
  width: 70%;
  padding: 15px 20px;
  box-sizing: border-box;
  margin: 0 auto;
}
.image-lit {
  width: 50%;
}
.images img {
  width: 100%;
}
.code-part {
  // overflow-x: auto;
  background-color: #eef0f4;
  position: relative;
  margin: 10px 15px;
  box-sizing: border-box;
}
.guthub{
    cursor:pointer;
    margin: 50px auto;
    width: 160px;
    height: 50px;
    border-radius: 25px;
    background: #f6f6f6;
    display: flex;
    align-items: center;
    justify-content: center;
}
.guthubimg{
    width: 40px;
    height: 40px;
    padding-right: 10px;
    img{
        width: 100%;
    }
}
.guthubtit{
        font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.1em;
    padding-top: 5px;
    color: #4f5959;
}

</style>