import { render, staticRenderFns } from "./Kaifa.vue?vue&type=template&id=14d7cfbc&scoped=true"
import script from "./Kaifa.vue?vue&type=script&lang=js"
export * from "./Kaifa.vue?vue&type=script&lang=js"
import style0 from "./Kaifa.vue?vue&type=style&index=0&id=14d7cfbc&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14d7cfbc",
  null
  
)

export default component.exports